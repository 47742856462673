"use client";

import { type ReactElement } from "react";
import { useRouter } from "next/navigation";
import { Button } from "@/components/button";

export default function Page(): ReactElement {
  const router = useRouter();
  const handleNavigation = (url: string): void => {
    router.push(url);
  };
  return (
    <>
      <div className="top-0 text-lg px-6 text-center">
        Welcome to the digital platform for veterinary prescriptions that makes
        pet medication affordable and accessible to owners.
      </div>
      <div className="flex flex-row mt-8 gap-y-6 items-center justify-center flex-wrap">
        <Button
          size="sm"
          onClick={() => {
            handleNavigation("/about/what-we-do");
          }}
        >
          What We Do
        </Button>
        <Button
          size="sm"
          onClick={() => {
            handleNavigation("/about/why-trust-us");
          }}
        >
          Why Trust Us
        </Button>
        <Button
          size="sm"
          onClick={() => {
            handleNavigation("/about/who-we-are");
          }}
        >
          Who We Are
        </Button>
      </div>
    </>
  );
}
